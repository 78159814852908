import { Grid, Box, Typography, Button } from "@mui/material";
import React from "react";
import classes from "../Styles/project.module.css";
import { FiLink } from "react-icons/fi";
const Project = ({ name, index, description, icon, link, image }) => {
  return (
    <>
      <Grid item lg={4} sm={6} xs={12} sx={{ pl: 3, pr: 1 }}>
        <Typography sx={{ pl: 2, pb: 2, pt: 2 }}>
          <span className={classes.proj_title}>
            {" "}
            {index}
            {"."}
          </span>{" "}
          <span className={classes.proj_name}>{name}</span>
        </Typography>
        <Box className={classes.proj}>
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "55%",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              width: "100%",
            }}
          />
          <Typography
            sx={{
              color: "#6f88a3",
              height: "45px",
              fontSize: "14px",
              p: 2,
              wordBreak: "break-word",
              fontFamily: "'Fira Code', monospace !important",
            }}
          >
            {description}
          </Typography>
          <a
            style={{ textDecoration: "none" }}
            href={`${link}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              size="large"
              sx={{
                color: "white",
                fontSize: "12px",
                backgroundColor: "#1C2B3A",
                fontFamily: "'Fira Code', monospace !important",
                ml: 2,
              }}
            >
              view-project
              <FiLink style={{ marginLeft: "7px" }} />
            </Button>
          </a>
          <Box className={classes.proj_icon}>{icon}</Box>
        </Box>
      </Grid>
    </>
  );
};

export default Project;
